<template>
  <div class="phone-verify">
    <div class="description">
      {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'forms.phoneVerificationDescription') }} +{{ props.phone }}
    </div>

    <form-input-code
      ref="codeInput"
      v-model:completeCode="completeCode"
      :hint="props.errorHint"
    />

    <div class="phone-verify__resend">
      <span class="phone-verify__resend-hint">
        <template v-if="limitError">
          {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'forms.verification.limitError') }}
        </template>
      </span>

      <template v-if="!limitError">
        <div v-if="timer" class="phone-verify__resend-timer">
          <div class="label">
            {{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'forms.verification.timerLabel') }}
          </div>

          <div class="time">
            <span>{{ Math.floor(currentTime / 60) }}</span>:<span>{{ String(currentTime % 60).padStart(2, '0') }}</span>
          </div>
        </div>

        <div v-else class="resend" @click="resendCode">
          <span>{{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'forms.verification.resendText') }}</span>
          <span class="link">{{ getContent(fieldsSettings, defaultLocaleFieldsSettings, 'forms.verification.resendlink') }}</span>
        </div>
      </template>
    </div>

    <div class="phone-verify__actions">
      <button-base
        class="phone-verify__cancel-button"
        tagName="div"
        type="gray"
        size="md"
        @click="emit('cancel')"
      >
        {{ props.buttonCancelLabel }}
      </button-base>

      <button-base
        class="phone-verify__verify-button"
        tagName="div"
        type="primary"
        size="md"
        :isDisabled="!completeCode || props.errorHint || props.loading || limitError"
        @click="verifyPhone"
      >
        <atomic-spinner :is-shown="props.loading"/>
        {{ props.buttonSubmitLabel }}
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const props = defineProps<{
    phone: string;
    reason: 'registration'|'phoneVerification'|'changingPass';
    errorHint?: { variant: string, message: string };
    loading: boolean;
    buttonSubmitLabel: string;
    buttonCancelLabel: string;
  }>();

  const emit = defineEmits(['verifyPhone', 'removeErrorHint', 'cancel']);
  const {
    fieldsSettings,
    defaultLocaleFieldsSettings
  } = storeToRefs(useGlobalStore());
  const { getContent } = useProjectMethods();
  const completeCode = ref<string>('');

  watch(completeCode, () => {
    if (props.errorHint) emit('removeErrorHint');
  });

  const TIMER_TIME = 60;
  const timer = ref<any>(undefined);
  const currentTime = ref<number>(TIMER_TIME);

  const startTimer = ():void => {
    timer.value = setInterval(() => {
      if (currentTime.value === 0) {
        clearInterval(timer.value);
        timer.value = undefined;
        return;
      }
      currentTime.value -= 1;
    }, 1000);
  };

  const resendingCode = ref<boolean>(false);
  const limitError = ref<boolean>(false);
  const resendCode = async (): Promise<void> => {
    if (resendingCode.value) return;

    resendingCode.value = true;

    try {
      const { sendOtp } = useCoreAuthApi();
      await sendOtp({ phone: props.phone, reason: props.reason });
      currentTime.value = TIMER_TIME;
      startTimer();
    } catch (error: any) {
      if (error.data?.error?.code === 11005) limitError.value = true;
      else throw error;
    }

    resendingCode.value = false;
  }

  const verifyPhone = async(): Promise<void> => {
    if (props.loading) return;
    emit('verifyPhone', completeCode.value);
  }

  const codeInput = ref();
  onMounted(() => {
    startTimer();
    codeInput.value.focusInput();
  });

  onBeforeUnmount(() => {
    clearInterval(timer.value);
  });
</script>

<style src="~/assets/styles/components/form/phone-verify.scss" lang="scss" />
