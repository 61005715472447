<template>
  <vue-final-modal
    class="modal-phone"
    :clickToClose="false"
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
  >
    <div class="scroll">
      <div class="header">
        <button-modal-close @click="closeModal(ModalName.PHONE)" />
        <div class="title">{{ getContent(popupsData, defaultLocalePopupsData, 'phone.modalTitle') }}</div>
      </div>

      <div class="modal-phone__form">
        <form-input-phone
          v-if="!isShowPhoneVerification"
          :label="getContent(popupsData, defaultLocalePopupsData, 'phone.label')"
          :placeholder="getContent(popupsData, defaultLocalePopupsData, 'phone.placeholder')"
          :hint="setError('phone')"
          v-model:value="phoneForm.phone"
        />

        <form-phone-verify
          v-if="isShowPhoneVerification"
          :phone="phoneForm.phone as string"
          reason="phoneVerification"
          :errorHint="verificationError"
          :loading="verifyLoading"
          :buttonSubmitLabel="getContent(layoutData, defaultLocaleLayoutData, 'buttons.submit')"
          :buttonCancelLabel="getContent(layoutData, defaultLocaleLayoutData, 'buttons.back')"
          @verify-phone="verifyPhone"
          @remove-error-hint="verificationError = undefined"
          @cancel="closeVerify"
        />
      </div>
      <div class="modal-phone__actions" v-if="!isShowPhoneVerification">
        <button-base
          type="gray"
          size="md"
          @click="closeModal(ModalName.PHONE)"
        >
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.cancel') }}
        </button-base>

        <button-base
          type="primary"
          size="md"
          @click="sendVerifyCode"
          :isDisabled="sendingCode"
        >
          <atomic-spinner :is-shown="sendingCode"/>
          {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.save') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { VueFinalModal } from 'vue-final-modal';

  import type { IFieldHint } from '~/types';
  import { ModalName } from "@skeleton/consts/modals";

  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);
  const { closeModal } = useModalStore();
  const globalStore = useGlobalStore();
  const { getContent } = useProjectMethods();

  const {
    layoutData,
    defaultLocaleLayoutData,
    fieldsSettings,
    defaultLocaleFieldsSettings,
    popupsData,
    defaultLocalePopupsData
  } = storeToRefs(globalStore);

  const verificationError = ref<IFieldHint|undefined>();

  const phoneForm = reactive({
    phone: profile.value?.phone as string
  });

  const { getFormRules, createValidationRules } = useProjectMethods();
  const phoneRules = createValidationRules([{ name: 'phone', isRequired: true }], true);
  const phoneFormRules = getFormRules(phoneRules);
  const { serverFormErrors, v$, setError } = useFormValidation(phoneFormRules, phoneForm);

  const sendingCode = ref(false);
  const isShowPhoneVerification = ref(false);

  const sendVerifyCode = async (): Promise<void> => {
    v$.value.$reset();
    const validFormData = await v$.value.$validate();

    if (sendingCode.value || !validFormData) return;
    sendingCode.value = true;

    try {
      const { sendOtp } = useCoreAuthApi();
      await sendOtp({ phone: profile.value?.phone as string, reason: 'phoneVerification' });
      isShowPhoneVerification.value = true;
    } catch (error:any) {
      if (error.data?.error?.code === 11005) {
        const limitError = getContent(fieldsSettings.value, defaultLocaleFieldsSettings.value, 'forms.verification.limitError');
        serverFormErrors.value = { phone: [limitError] };
      } else {
        serverFormErrors.value = { phone: [error.data?.error?.message] };
      }
    }

    sendingCode.value = false;
  }

  const verifyLoading = ref(false);
  const verifyPhone = async (verificationCode: string): Promise<void> => {
    if (verifyLoading.value) return;
    verifyLoading.value = true;

    try {
      const { phoneVerification } = useCoreAuthApi();
      await phoneVerification({
        phone: profile.value?.phone as string,
        reason: 'phoneVerification',
        code: verificationCode
      });
      await profileStore.getProfileData();
      isShowPhoneVerification.value = false;
    } catch (error: any) {
      if (error.data?.error?.code === 11003) {
        verificationError.value = {
          variant: 'error',
          message: getContent(fieldsSettings.value, defaultLocaleFieldsSettings.value, 'forms.verification.invalidError')
        };
      } else {
        verificationError.value = {
          variant: 'error',
          message: error.data?.error?.message || 'Cannot verify phone number'
        };
      }
    }
    verifyLoading.value = false;
  }

  const closeVerify = (): void => {
    v$.value.$reset();
    isShowPhoneVerification.value = false;
  }
</script>

<style src="~/assets/styles/components/modal/phone.scss" lang="scss" />

